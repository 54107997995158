import { AdName } from "./ad/ad";
import { AdConfig } from "./ad/ads-config";

export class Unit {
  id: string;
  count: number;
  name: AdName;
  
  constructor(name: AdName, unitCount: number) {
    this.name = name;
    this.count = unitCount;
    this.id = AdConfig.get(name) + unitCount;
  }
}

