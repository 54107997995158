import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import {
  LAZY_SCRIPT_NAME,
  LazyScriptLoaderService,
} from "@utils/services/lazy-script-loader/lazy-script-loader.service";
import {
  AdBreakpoints,
  AdConfig,
  MAX_CONTENT_ADS,
} from "../models/ad/ads-config";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ConsentService } from "@utils/services/shared-consent/consent.service";
import { AdCampaignService } from "@components/ad-campaign/services/ad-campaign.service";
import {
  LocalStorageKey,
  SessionStorageKey,
} from "@utils/storage/storage-keys";
import { AdName } from "@lib-modules/ads/models/ad/ad";
import { AdEventType } from "@lib-modules/ads/models/ad-event-type";
import { Unit } from "../models/unit";



@Injectable({
  providedIn: "root",
})
export class AdService {
  public isHomePage = false;
  public adEvent = new Subject<{ type: AdEventType; adSlotId: string }>();
  public leftSideBarAd = AdName.BesideLeft;
  public rightSideBarAd = AdName.BesideRight;
  public breakpoints = new BehaviorSubject<{ [key: string]: boolean }>({});
  public initialized = false;
  public displayRealAdsSetting = false;
  public displayTestAdsSetting = false;
  private refreshBlocked = false;
  private googleTag: any;
  private activeUnitCount = new Map<AdName, number>();
  private activeUnits = new Map<string, Unit>();
  private mappingDone = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private lazyScriptLoader: LazyScriptLoaderService,
    private breakpointsObserver: BreakpointObserver,
    private consentService: ConsentService,
    private adCampaignService: AdCampaignService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.displayRealAdsSetting = true; // sessionStorage.getItem(SessionStorageKey.RealAdsActive) === 'true';
      // this.displayTestAdsSetting = localStorage.getItem(LocalStorageKey.TestAdsActive) === 'true';
      this.registerCampaignChange();
      this.consentService.consentChange.subscribe((consent) => {
        if (consent.action === "accept") {
          this.init();
        }
      });
    }
  }

  public createUnit(adName: AdName): Unit {
    // Append unit count for content ads example: .../content_1, .../content_2
    let unitCount = this.activeUnitCount.get(adName) ?? 0;
    // After content_4 use infinity_1, infinity_2 etc.
    if (unitCount >= MAX_CONTENT_ADS) {
      adName = AdName.Infinity;
      unitCount = this.activeUnitCount.get(adName) ?? 0;
    }
    unitCount++;
    this.activeUnitCount.set(adName, unitCount);
    return new Unit(adName, unitCount);
  }

  public async init(): Promise<void> {
    if (!isPlatformBrowser(this.platformId) || this.initialized) {
      return;
    }
    this.initialized = true;
    console.log("[Ad Service] Initialize");
    this.observeBreakpoints();
    // Load the SPM Script, append "?cpg_debug=true" to the current url to open debug window
    await this.lazyScriptLoader.load(
      LAZY_SCRIPT_NAME.SPORTPLATZ_MEDIA_ADS
    );
    console.log("[Ad Service] Sportplatz Media script initialized");
  }

  public async load(unit?: Unit) {
    if (!this.initialized) {
      return;
    }
    this.activeUnits.set(unit.id, unit);
    console.log('Units: ', this.activeUnits);
    // If a campaign target is set, await campaign load with targeting info before loading ads
    if (this.adCampaignService.hasTarget() && !this.adCampaignService.campaignReady) {
      return;
    }

    const isInfinityAd = unit ? unit.id.includes(AdName.Infinity) : false;
    const adHandler = this.getAdHandler();

    // Request new and destroyed ads
    if (this.mappingDone) {
      adHandler.cmd.push(() => {
        console.log("[Ad Service] Request");
        adHandler.service.request();
      });
    }

    if (!this.mappingDone) {
      this.mappingDone = true;
      adHandler.cmd.push(() => {
        console.log("[Ad Service] Mapping");
        adHandler.mapping_ros();
      });
    }

    if (isInfinityAd) {
      adHandler.cmd.push(() => {
        console.log("[Ad Service] New infinity ad", unit.id);
        (window as any).newInfinityAdUnit(unit.count);
      });
    }

    const targetingInfo = this.adCampaignService.getAdTargeting();
    if (targetingInfo?.value?.length) {
      adHandler.cmd.push(() => {
        adHandler.service.getAdUnit(unit.id)?.setTargeting(targetingInfo.channel_name, targetingInfo.value);
      });
    }
    if (this.isHomePage) {
      adHandler.cmd.push(() => {
        adHandler.service.getAdUnit(unit.id)?.setTargeting("fan.at_channel", "homepage");
      });
    }
  }

  public showSidebarAds(show = true): void {
    this.leftSideBarAd = show ? AdName.BesideLeft : null;
    this.rightSideBarAd = show ? AdName.BesideRight : null;
  }

  public refreshSidebarAds() {
    if (!this.refreshBlocked) {
      this.blockAdRefresh();
      console.log("[Ad Service] Refresh sidebar ads");
      const leftSideBarAdId = AdConfig.get(AdName.BesideLeft) + "1";
      const rightSideBarAdId = AdConfig.get(AdName.BesideRight) + "1";
      this.destroy(this.activeUnits.get(leftSideBarAdId));
      this.destroy(this.activeUnits.get(rightSideBarAdId));
    }
  }

  public refreshAll(force = false): void {
    if (!this.initialized) {
      return;
    }
    // To conform with ÖWA Rules a refresh should only occur every 15s
    if (!force && this.refreshBlocked) {
      return;
    }
    this.blockAdRefresh();
    console.log(`[Ad Service] Refresh all`);
    // The next ad load will automatically request new ads 
    this.destroyAll();
    this.load();
  }

  public destroyAll() {
    console.log("[Ad Service] Destroy all");
    // this.activeUnits.forEach((unit) => this.destroy(unit));
    const adHandler = this.getAdHandler();
    adHandler.service.destroy();
    this.mappingDone = false;
    this.activeUnitCount.clear();
    this.activeUnits.clear();
  }

  public destroy(unit: Unit) {
    const adHandler = this.getAdHandler();
    const ad = adHandler.service.getAdUnit(unit.id);
    console.log("[Ad Service] Destroy", ad);
    ad?.destroy();
    this.activeUnits.delete(unit.id);
    let count = this.activeUnitCount.get(unit.name);
    count -= count > 0 ? 1 : 0;
    this.activeUnitCount.set(unit.name, count);
  }
  
  private async trackAdEvents(): Promise<void> {
    // await this.lazyScriptLoader.load(LAZY_SCRIPT_NAME.GOOGLE_GPT);
    const { googletag } = window as any;
    this.googleTag = googletag;
    this.googleTag.cmd.push(() => {
      Object.values(AdEventType).forEach((eventName) => {
        this.googleTag.pubads().addEventListener(eventName, (event) => {
          this.adEvent.next({
            type: eventName,
            adSlotId: event.slot.getSlotElementId(),
          });
        });
      });
    });
  }

  private blockAdRefresh() {
    this.refreshBlocked = true;
    setTimeout(() => (this.refreshBlocked = false), 15000);
  }

  private registerCampaignChange(): void {
    this.adCampaignService.campaignChange.subscribe(() => {
      this.activeUnits.forEach((unit) => this.load(unit));
    });
  }

  private observeBreakpoints(): void {
    const breakPoints = Object.values(AdBreakpoints);
    this.breakpointsObserver.observe(breakPoints).subscribe((result) => {
      this.breakpoints.next(result.breakpoints);
    });
  }

  private getAdHandler() {
    if (!(window as any).adHandler) {
      (window as any).adHandler = (window as any).adHandler || { cmd: [] };
    }
    return (window as any).adHandler;
  }
}
